// Easingの追加
jQuery.easing.quart = function (x, t, b, c, d) {
    return -c * ((t=t/d-1)*t*t*t - 1) + b;
};
 
$(document).ready(function(){
	var browser=browserCheck();
	var os=osCheck();
	var device=deviceCheck();
	$("html").addClass(browser).addClass(os);


  // <a href="#***">の場合、スクロール処理を追加
  $('a[href*="#"]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
  		var $target = jQuery(this.hash);
      $target = $target.length && $target || jQuery('[name="' + this.hash.slice(1) +'"]');
      if ($target.length) {
      	var targetOffset = $target.offset().top;
        $('html,body').animate({ scrollTop: targetOffset }, 800, 'quart');
				return false;
      }
    }
  });
  
  $('.anm').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('is-show');
    } else {
    //表示領域から出た時
     	//$(this).removeClass('is-show');
    }
  });
  
  if($(".mh").length){
		$(".mh").matchHeight();
	}
	if($(".objfit").length){
	  objectFitImages('img.objfit');
  }
  if($('img[usemap]').length){
	  $('img[usemap]').rwdImageMaps();
	}
  $("#menu-btn").click(function(){
	  $(this).parent().toggleClass("open");
  });
	
	if($("#gmap").length){
		googlemap({
			"tit" : "ブレイン・アンド・キャピタル",
			"lat" : 35.6671033,
			"lng" : 139.7401427,
			"maplat" : 35.6671033, //mapの中心
			"maplng" : 139.7401427, //mapの中心
			"zoom" : 17,
			"window" : "<dl id='infowin'><dt>ブレイン・アンド・キャピタル・<br>ホールディングス株式会社</dt><dd>〒107-6090<br>東京都港区赤坂1-12-32<br>アーク森ビル13階</dd></dl>"
		});
	}  

	if($(".validationform").length){
		/*inputの下にエラー
		var validation = $(".validationform").exValidation({
			errInsertPos :"after",
			errPosition :"fixed",
		  stepValidation: true,
		  errHoverHide:false,
		  errTipCloseBtn:false,
		  scrollAdjust:0,
		  errMsgPrefix : "",
		});*/
		
		/*エラー吹き出し表示*/
		var validation = $(".validationform").exValidation({
		  stepValidation: true,
		  errHoverHide:true,
		  errTipCloseBtn:false,
		  scrollAdjust:0,
		  errMsgPrefix : "",
		});
	}
	
	/*$("#submit").click(function(e){
		$(this).parents("form").submit();
	});*/
	
	//画面サイズごとの処理
	var w = $(window).width();
	deviceFunc(w);
	
	$(window).resize(function(){
  	var w = $(window).width();
	  deviceFunc(w);
	});
	
});

//画面サイズごとの処理
function deviceFunc(w){
	var bp = [599,960,1280];
	if (w <= bp[0]) {
		//sp
		$(".tel-number").each(function(){
			if(!$(this).parent(".tel-wrap").length){
				var no = $(this).data("tel");
				$(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
			}
		});
  } else if(w <= bp[1]){
		//tb
		$(".tel-wrap .tel-number").each(function(){
			$(this).unwrap();
		});
  } else if(w <= bp[2]){
		//small pc
  } else{
  
  }
}
function googlemap(opt){
	var latlng = new google.maps.LatLng(opt.lat,opt.lng);
	var maplatlng = new google.maps.LatLng(opt.maplat,opt.maplng);
  var myOptions = {
    zoom: opt.zoom, /*拡大比率*/
    center: maplatlng, /*表示枠内の中心点*/
    tilt:0,
    scrollwheel: false,
    mapTypeControl:false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,/*表示タイプの指定*/
    styles: [{
        featureType: 'all',
        elementType: 'all',
        stylers: [{
            hue: '#267994' //16進数のRGB値を記載
        }, {
            saturation: -70 //-100〜100の値を記載
        }, {
            lightness: 0 //-100〜100の値を記載
        }, {
            gamma: 0.8 //0.01〜10.0の値を記載
        }]
    }]
  };
  var map = new google.maps.Map(document.getElementById('gmap'), myOptions);
 
	/*アイコン設定▼*/
  var markerOptions = {
    position: latlng,
    map: map,
    title: opt.tit
  };
  var marker = new google.maps.Marker(markerOptions);
　/*アイコン設定ここまで▲*/

	/*情報ウィンドウ*/
  var contentString=opt.window;
  var infowindow=new google.maps.InfoWindow({
      content: contentString
  });
  infowindow.open(map,marker);//表示
  /* マーカーがクリックされた時に情報ウィンドウ表示 */
  google.maps.event.addListener(marker, 'click', function() {
      infowindow.open(map,marker);
  });
}


/*ブラウザ判別*/
function browserCheck(){
	var ua = window.navigator.userAgent.toLowerCase();
	var av = window.navigator.appVersion.toLowerCase();
	if(ua.indexOf('edge') != -1){
		return 'edge';
	}else if (ua.indexOf('opera') != -1) {
  	return 'opera';
	}else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
		$("body").addClass('ie');
  	if (av.indexOf("msie 6.") != -1) {
    	return 'ie6';
  	} else if (av.indexOf("msie 7.") != -1) {
    	return 'ie7';
  	} else if (av.indexOf("msie 8.") != -1) {
    	return 'ie8';
  	} else if (av.indexOf("msie 9.") != -1) {
    	return 'ie9';
  	}else if (av.indexOf("msie 10.") != -1) {
    	return 'ie10';
  	}else if (av.indexOf("rv:11.") != -1) {
    	return 'ie11';
  	}
	} else if (ua.indexOf('chrome') != -1) {
  	return 'chrome';
	} else if (ua.indexOf('safari') != -1) {
  	return 'safari';
	} else if (ua.indexOf('gecko') != -1) {
  	return 'firefox';
	} else {
  	return false;
	}
}

/*OS判別*/
function osCheck(){
	var ua = window.navigator.userAgent.toLowerCase();
	if (ua.indexOf('windows') != -1) {
		if(ua.indexOf('phone') != -1){
			return 'winphone';
		}else{
			return 'win';
		}
	}else if(ua.indexOf('iphone') != -1) {
		return 'iphone';
	}else if(ua.indexOf('ipad') != -1) {
		return 'ipad';
	}else if(ua.indexOf('mac') != -1) {
		return 'mac';
	}else if(ua.indexOf('android') != -1) {
		if(ua.indexOf('mobile') != -1){
			if(ua.indexOf('A1_07') != -1){
				return 'tablet';
			}else if(ua.indexOf('SC-01C') != -1){
				return 'tablet';
			}else{	return 'android';}
		}else{
			return 'tablet';
		}
	}else{
		return false;
	}
}

/*デバイス判別*/
function deviceCheck(){
	var ua = window.navigator.userAgent.toLowerCase();
	if((ua.indexOf("windows") != -1 && ua.indexOf("touch") != -1 && ua.indexOf("tablet pc") == -1) 
      || ua.indexOf("ipad") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1)
      || ua.indexOf("kindle") != -1
      || ua.indexOf("silk") != -1
      || ua.indexOf("playbook") != -1){
      return 'tab';
    }else if((ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1)
      || ua.indexOf("iphone") != -1
      || ua.indexOf("ipod") != -1
      || (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1)
      || (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1)
      || ua.indexOf("blackberry") != -1){
	    return 'sp';
    }else{
      return 'pc';
    }
}